<template>
  <div class="flex flex-col mb-10">
    <div style="margin-left: -10px; margin-top: 20px">
      <div class="md-layout-item md-size-100 mb-3" v-for="(input,k) in questions" :key="k">
        <label  class="text-uppercase text-base font-semibold mb-1 inline-block text-italic">Question {{k+1}}#</label>
          <CheckboxComponent v-if="input.question_type=='CHECK_LIST'"
            :label="input.title"
            label-class="font-bold"
            :label-outline="false"
            :block="true"
            :items="getItems(input.job_question_details)"
            v-model.trim="form.question_answer[input.id]"

            />
          <TextAreaComponent v-if="input.question_type=='SHORT_ANSWER'"
                placeholder="Write Question"
                :label="input.title"
                :rows = 5
                v-model.trim="form.question_answer[input.id]"
            />
            <TextAreaComponent v-if="input.question_type=='LONG_ANSWER'"
                placeholder="Write Question"
                :label="input.title"
                :rows = 10
                v-model.trim="form.question_answer[input.id]"
            />
             <label v-if="input.question_type=='RADIO_BUTTON'" class="text-uppercase font-bold text-xl mb-2 block mt-6"> {{input.title}} </label>
            <RadioButtonComponent v-if="input.question_type=='RADIO_BUTTON'"
              label-class="font-bold"
              align="align-left"
              :label-outline="false"
              :block="true"
              :items="getItems(input.job_question_details)"
              v-model.trim="form.question_answer[input.id]"
            />
    </div>
    </div>
    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Save & Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  CheckboxComponent,
  TextAreaComponent,
  RadioButtonComponent
 
 
} from "@/components";
import moment from 'moment';
import { mapActions } from "vuex";
// import Question from "@/components/molecule/question/items/Question";

export default {
  components: {
    Button,
    CheckboxComponent,
    TextAreaComponent,
    RadioButtonComponent
    // Question

  },

  data() {
    return {
      loading: false,
      radio: false,
      inputs: [{
            name: '',
      }],
      form: {
        question_answer :{}
      },
      questions: [],
    };  
  },     

  methods: {
    ...mapActions({
      actGetJobQuestions: "job/actGetQuestionsByJobId",
    }),
    customFormatter(date) {
      return moment(date).format('YYYY-MM-D');
    },
    getItems(data){

        return data.map((item) =>{
          return { id: item.key, label: item.value }
        });
    },

    submit() {
      // console.log(this.form.question_answer);
      // return true;
        let formData = this.getLocal('job-application-basic-info');
        formData.pte_score_card = {
          ...this.form.question_answer
        };
        this.setLocal('job-application-basic-info', formData);
        this.$emit("menu", this.$route.name);
        this.$router.push({ name: 'reviewAndSubmit' });
    },

    clearAll() {
      this.form = {
        question_answer :{}
      };

      let formData = this.getLocal('job-application-basic-info');
      console.log(formData);
      formData.pte_score_card = [
        ...this.form.question_answer
      ];

      this.$emit("menu", this.$route.name+'#false');
      this.setLocal('job-application-basic-info', formData);
    }
  },

  created() {
    let existData = this.getLocal('job-application-basic-info');

    if(existData && existData.pte_score_card) this.form.pte_score_card = existData.pte_score_card;

    this.actGetJobQuestions(existData.job_circular_id)
      .then( ( {items} ) => {
        this.questions = items;
      });
  }
}
</script>
<style scoped>
.nav-check{
  display: block!important;
}
.vdp-datepicker__clear-button{
  position: absolute;
  margin-left: -15px;
  margin-top: 15px;
  padding: -2px;
  font-weight: bold;
  color: red;
}
.date-picker-icon {
    top: 15px!important;
    right: 15px!important
}
.justify-content-center{
  justify-content: center;
}
    
</style>